html,
body {
  height: 100%;
  margin: 0px;
  min-width: 210px;
}

body {
  height: 100%;
  padding: 0;
  background-color: #fff;
  /* background-color: #757575; */
  /* background-image: url('../images/pattern.jpg'); */
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0 50px 0;
}

.flex-container > div {
  width: 55%;
  max-width: 550px;
  padding: 30px;
  background-color: #fff;
}

.flex-container .button-wrapper {
  text-align: center;
}

.none-flex-container {
  padding: 10px;
}


.none-flex-container .button-wrapper {
}

.profile .flex-container .button-wrapper,
.home .flex-container .button-wrapper {
  padding-bottom: 30px;
}

.compose .flex-container .button-wrapper {
  padding-top: 10px;
}

h1 {
  font-size: 1em !important;
}

.none-flex-container h2, .flex-container h2 {
  font-size: 1.1em !important;
  margin: 0.5rem 0;
}

.flex-container h3 {
  font-size: 1em !important;
}

.airport-info {
  text-align: center;
}

textarea:disabled,
input:disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}

#root {
  height: 100%;
}

#content {
  box-shadow: 1px 0 5px -2px #888;
  min-height: 100%;
  padding: 0;
  margin: 0;
  color: #000;
}

#content.desktop {
  padding-left: 224px;
  background-color: #f0f0f0;
}

#content.desktop.getprofileinfo,
#content.desktop.language,
#content.desktop.privacystatement {
  padding-left: 0px;
}

#content.desktop.privacystatement {
  background-color: #fff;
}

#content.desktop.login {
  padding: 100px 0 0 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 80%;
}

#content.desktop.login > div {
  width: 550px;
}

#content.mobile {
  padding-left: 0;
  background-color: #fff;
}

h1,
h2,
h3,
h3,
h5,
h6,
b,
strong {
  font-family: "Roboto", sans-serif;
}

div,
p,
span,
ul,
li,
a {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.auth0-lock.auth0-lock .auth0-lock-submit {
  padding: 0px !important;
  height: 40px !important;
}

.spinner {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.spinner:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #dedede;
  border-color: #dedede transparent #dedede transparent;
  animation: spinner 1.2s linear infinite;
}

#homeHeader img {
  background-color: transparent !important;
  border-radius: 0 !important;
}

.required {
  color: #ff0000;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.privacy-policy {
  font-size:10px;
  overflow-y: scroll !important;
}

.version {
  font-size: 33px;
}

/* @media screen and (min-width: 1024px) {
  #content {
    max-width: 1024px;
  }

  #content>div {
    max-width: 1024px !important;
  }
} */

.submitNowButton button {
  background: linear-gradient(#7CF, #047 33%, #09F);
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}
.submitNowButton button:hover {
  background: linear-gradient(#5AF, #047 33%, #036);
}
.submitNowButton button div {
  background: transparent !important;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.submitNowButton2 {
  display: block;
  width: 100%;
  margin: 0 0 0.5rem 0;
  background: linear-gradient(-45deg, #e75, #e37, #2ad, #2da);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: #FFF;
  border: 0;
  font-size: 1.5rem;
  line-height: 48px;
  padding: 20px;
  border-radius: 10px;
  transition: all 1s ease-out;
  text-align: left;
}
.submitNowButton2:hover, .submitNowButton2:active, .submitNowButton2:focus {
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5);
  filter: contrast(2);
  transition: all 1s ease-out;
  outline: 0;
}
.submitNowButton2 svg {
  display: block !important;
  float: left;
  fill: #FFF !important;
  width: 48px;
  height: 48px;
  margin: 10px 2rem;
}
.submitNowButton2:hover svg {
  transform: scale(2);
  transition: all 1s ease-out;
}

.submitNowButton2[disabled] {
  filter: saturate(0);
  opacity: 0.5;
}

.mobile .submitNowButton2 {
  font-size: 1rem;
}

.mobile .submitNowButton2 svg {
  margin: 10px;
}

select, option {
  padding-left: 5px !important;
}

.flex-container .submit-now-heading h2  {
  font-size: 1.5rem !important;
}

.reportCount { background: #176A88; color: #FFF; padding: 1rem 2rem; border-radius: 10px; }
.reportCountToday, .reportCount90Days  { font-size: 1rem; line-height: 1.5rem; }
.reportCount span { font-weight: bold; font-size: 1.5rem; line-height: 1.5rem; width: 80px; display: inline-block; text-align: right; padding-right: 10px; }

.mobile .reportCount { background: #176A88; color: #FFF; padding: 1rem 1rem; border-radius: 10px; }
.mobile .reportCountToday, .mobile .reportCount90Days { font-size: 1rem; line-height: 1.25rem; }
.mobile .reportCount span { font-size: 1.25rem; width: 50px; }

.button0 { background-size: 300% 300%; }
.button1 { background-size: 400% 400%; }
.button2 { background-size: 500% 500%; }
.button3 { background-size: 600% 600%; }


.submitNowOptionButton {
  line-height: 1.2;
  height: 100%;
  min-height: 36px;
  width: 100%;
  background: none;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 2px;
  padding: 0 10px;
}

.submitNowOptionButton.selected {
  background: rgb(23, 106, 136);
  color: rgb(255, 255, 255);
}

.submitNowOptionButton:hover, .submitNowOptionButton:focus {
  background-color: rgba(0, 0, 0, 0.08);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.submitNowOptionButton.selected:hover, .submitNowOptionButton.selected:focus {
  background: rgba(23, 106, 136, .5);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.historyMonth { font-size: 20px; margin: 10px 0; }

.complaintRow { display: block; padding: 0 0; }
.complaintRowTop, .complaintRowBottom { display: flex;  }
.compCell { flex: 1 1 0; padding: 0.5rem; color: #000; }
.visx-legend-label { color: #000; }
.compDate { flex-grow: 1.5; }
.compTypeColor { flex-grow: 0; }
.compType { flex-grow: 2; }
.compWebTrakLink { flex-grow: 0; }
.compTypeColorBadge { width: 1rem; height: 1rem; border-radius: 50%; }

.pieChartContainer { display: flex; align-items: center; padding-top: 1rem; }
.pieChartContainerLeft { flex: 3 1 0; }
.pieChartContainerRight { flex: 2 1 0; }
